<template>
  <section v-if="property.id">
    <div class="max-w-screen-lg mx-auto px-4">
      <div class="grid" style="border-top: 1px solid white">
        <div class="grid">
          <div class="grid z-20" style="height: 480px">
            <!-- GALERIE -->
            <div class="w-full z-10 flex justify-center" style="height: 480px">
              <router-link v-if="enablePreview && !dataImages.length && !dataVideos.length"
                :to="'/priprava-nemovitosti'">
                <a target="_blank">
                  <img style="height: 480px" src="/images/photodefaultportrait.png" />
                </a>
              </router-link>
              <div v-else-if="!selectedCarouselItem.length && enablePreview && (dataImages || dataVideos)"
                class="flex justify-center">
                <a v-if="!dataVideos.length && dataImages.length" target="_blank" :href="dataImages[0].images">
                  <div>
                    <img style="height: 480px" :src="dataImages[0].images" />
                  </div>
                </a>
                <a v-if="dataVideos.length" target="_blank" :href="dataVideos[0].videos">
                  <video width="480" controls>
                    <source :src="dataVideos[0].videos" type="video/mp4" />
                  </video>
                </a>
              </div>
              <div v-if="selectedCarouselItem && !enablePreview" class="flex justify-center">
                <a v-if="selectedCarouselItem.type === 'image'" target="_blank" :href="selectedCarouselItem.src">
                  <img style="height: 480px" :src="selectedCarouselItem.src" />
                </a>
                <a v-else-if="selectedCarouselItem.type === 'video'" target="_blank" :href="selectedCarouselItem.src">
                  <video width="480" controls>
                    <source :src="selectedCarouselItem.src" type="video/mp4" />
                  </video>
                </a>
              </div>

            </div>
          </div>
          <!-- VYBER OBRAZKU -->
          <div v-if="dataVideos || dataImages"
            class="w-full z-20 bg-white flex overflow-auto bg-gray-50 justify-center items-center">
            <div v-for="video in dataVideos" :key="video.id" v-on:click="chooseMediaHandler(video, 'video')">
              <video class="opacity-90 hover:opacity-100 duration-200 cursor-pointer" width="64" height="64">
                <source :src="video.videos" type="video/mp4">
              </video>
            </div>
            <div v-for="image in dataImages" :key="image.id" v-on:click="chooseMediaHandler(image, 'image')">
              <div class="flex-1 w-16">
                <img style="border: 1px solid white"
                  class="mx-auto my-auto h-16 opacity-90 hover:opacity-100 duration-200 cursor-pointer"
                  :src="image.images" />
              </div>
            </div>
          </div>
        </div>
        <div class="block bg-white z-30">
          <h2 class="text-4xl font-bold text-body-4 my-12">
            {{ property.name }}
          </h2>

          <div class="grid grid-cols-1 md:grid-cols-2">
            <div class>
              <h2 class="text-body-4 text-4xl">
                {{
                  $const.PROPERTY_DISPOSITIONS[property.propertyDisposition - 1]
                    .name
                }}
                {{
                  property.usableAreaApartment
                    ? property.usableAreaApartment + "m²"
                    : "Nevyplněno"
                }}
              </h2>
            </div>
            <div class="grid grid-rows-2">
              <div class="justify-self-start md:justify-self-end mt-3 md:mt-0">
                <h2 class="text-4xl text-body-1 font-bold select-none">
                  {{ serialize(property.propertyPrice) }}
                </h2>
              </div>
            </div>
            <div>
              <h2 class="text-2xl md:text-3xl text-body-4 font-medium">
                {{ property.address ? property.address + ", " : null }}
                {{ property.city ? property.city + ", " : null }}
                {{ property.zipCode ? property.zipCode : null }}
              </h2>
            </div>
          </div>
          <div v-if="
            property.propertyDescription.length > 0 &&
            property.propertyDescription !== 'undefined'
          " class="mt-12">
            <p class="text-17 text-body-4">
              {{ property.propertyDescription }}
            </p>
          </div>
          <div v-if="
            property.dispositionViewDescription.length > 0 &&
            property.dispositionViewDescription !== 'undefined'
          " class="mt-12">
            <p class="text-17 text-body-4">
              {{ property.dispositionViewDescription }}
            </p>
          </div>
          <div v-if="
            property.surroundingDescription.length > 0 &&
            property.surroundingDescription !== 'undefined'
          " class="mt-12">
            <p class="text-17 text-body-4">
              {{ property.surroundingDescription }}
            </p>
          </div>
          <div v-if="
            property.outsideViewDescription.length > 0 &&
            property.outsideViewDescription !== 'undefined'
          " class="mt-12">
            <p class="text-17 text-body-4">
              {{ property.outsideViewDescription }}
            </p>
          </div>
          <div v-if="
            property.energyAndAdditionalDescription.length > 0 &&
            property.energyAndAdditionalDescription !== 'undefined'
          " class="mt-12">
            <p class="text-17 text-body-4">
              {{ property.energyAndAdditionalDescription }}
            </p>
          </div>
          <div class="mt-12">
            <div class="grid grid-cols-1 md:grid-cols-2">
              <div class="grid grid-cols-2">
                <div class="grid grid-rows-10">
                  <div class="my-4 text-body-1 font-bold text-15">
                    Základní informace
                  </div>
                  <div class="text-17 font-bold text-body-4">
                    Typ nemovitosti
                  </div>
                  <div class="text-17 font-bold text-body-4 pt-3">
                    Dispozice
                  </div>
                  <div class="text-17 font-bold text-body-4 pt-3">Stav</div>
                  <div class="text-17 font-bold text-body-4 pt-3">Adresa</div>
                  <div class="text-17 font-bold text-body-4 pt-3">Mesto</div>
                  <div class="text-17 font-bold text-body-4 pt-3">
                    Vlastnictvi
                  </div>
                  <div class="text-17 font-bold text-body-4 pt-3">
                    Užitná plocha
                  </div>
                  <div v-if="property.propertyAdditional.includes('1')" class="text-17 font-bold text-body-4 pt-3">
                    Balkón
                  </div>
                  <div v-if="property.propertyAdditional.includes('2')" class="text-17 font-bold text-body-4 pt-3">
                    Terasa
                  </div>
                  <div v-if="property.propertyAdditional.includes('3')" class="text-17 font-bold text-body-4 pt-3">
                    Lodžie
                  </div>
                  <div v-if="property.propertyAdditional.includes('4')" class="text-17 font-bold text-body-4 pt-3">
                    Sklep
                  </div>
                  <div v-if="property.propertyAdditional.includes('5')" class="text-17 font-bold text-body-4 pt-3">
                    Parkování
                  </div>
                  <div v-if="property.propertyAdditional.includes('6')" class="text-17 font-bold text-body-4 pt-3">
                    Výtah
                  </div>
                  <div v-if="property.propertyAdditional.includes('7')" class="text-17 font-bold text-body-4 pt-3">
                    Garáž
                  </div>
                  <div v-if="property.propertyAdditional.includes('8')" class="text-17 font-bold text-body-4 pt-3">
                    Klimatizace
                  </div>
                </div>

                <div class="grid grid-rows-10">
                  <div class="my-4 text-body-1 font-bold text-15 pt-6"></div>
                  <div class="text-17 text-body-4">
                    {{ $const.PROPERTY_TYPES[property.propertyType - 1].name }}
                  </div>
                  <div class="text-17 text-body-4 pt-3">
                    {{
                      $const.PROPERTY_DISPOSITIONS[
                        property.propertyDisposition - 1
                      ].name
                    }}
                  </div>
                  <div class="text-17 text-body-4 pt-3">
                    {{
                      $const.PROPERTY_STATES[property.propertyState - 1].name
                    }}
                  </div>
                  <div class="text-17 text-body-4 pt-3">
                    {{ property.address }}
                  </div>
                  <div class="text-17 text-body-4 pt-3">
                    {{ property.city }}
                    {{ property.zipCode }}
                  </div>
                  <div class="text-17 text-body-4 pt-3">
                    {{
                      $const.PROPERTY_OWNERSHIPS[property.propertyOwnership - 1]
                        .name
                    }}
                  </div>
                  <div class="text-17 text-body-4 pt-3">
                    {{
                      property.usableAreaApartment
                        ? property.usableAreaApartment + "m²"
                        : "Nevyplněno"
                    }}
                  </div>
                  <div v-if="property.propertyAdditional.includes('1')" class="text-17 text-body-4 pt-3">
                    {{
                      property.usableAreaBalcony
                        ? "Ano, " + (property.usableAreaBalcony + "m²")
                        : null
                    }}
                  </div>
                  <div v-if="property.propertyAdditional.includes('2')" class="text-17 text-body-4 pt-3">
                    Ano
                  </div>
                  <div v-if="property.propertyAdditional.includes('3')" class="text-17 text-body-4 pt-3">
                    Ano
                  </div>
                  <div v-if="property.propertyAdditional.includes('4')" class="text-17 text-body-4 pt-3">
                    {{
                      property.usableAreaCellar
                        ? "Ano, " + (property.usableAreaCellar + "m²")
                        : null
                    }}
                  </div>
                  <div v-if="property.propertyAdditional.includes('5')" class="text-17 text-body-4 pt-3">
                    Ano
                  </div>
                  <div v-if="property.propertyAdditional.includes('6')" class="text-17 text-body-4 pt-3">
                    Ano
                  </div>
                  <div v-if="property.propertyAdditional.includes('7')" class="text-17 text-body-4 pt-3">
                    Ano
                  </div>
                  <div v-if="property.propertyAdditional.includes('8')" class="text-17 text-body-4 pt-3">
                    Ano
                  </div>
                </div>
              </div>
            </div>
            <h2 class="text-body-1 text-15 font-bold my-4 mt-8">
              Energetická třída
            </h2>
            <div :style="{ borderColor: energyClassColor }" class="border-2 mt-4">
              <EnergyClass :color="energyClassColor" :value="property.energyClass" />
            </div>
          </div>
          <div v-if="openMap" class="mt-6">
            <h2 class="text-body-1 text-15 font-bold pb-2">Mapa</h2>
            <HereMap :center="center" :zoom="18" />
          </div>
          <div
            class="w-full bg-body-1 font-semibold justify-items-center content-center grid grid-cols-12 items-center lg:rounded text-white text-md pt-2 pb-2 pl-4 mt-8">
            <div class="col-span-10">{{ urlToCopy }}</div>
            <div class="cursor-pointer hover:bg-blue-600 py-2 px-2 rounded-xl" @click="copyHandler()">
              <div class="flex flex-row">
                <div class="mr-2">
                  <Copy :styles="'fill-svg'" />
                </div>
                <div class="leading-8">Kopírovat</div>
              </div>
            </div>
            <input type="hidden" id="copy" :value="urlToCopy" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import { getImagesNoLogged, getVideosNoLogged } from "../services/rest/services";
import HereMap from "../components/map/HereMap.vue";
import EnergyClass from "../components/svg/EnergyClass.vue";
import Copy from "../components/svg/Copy.vue";

export default {
  name: "PreviewPage",
  components: {
    HereMap,
    EnergyClass,
    Copy,
  },
  computed: {
    ...mapState("user", ["loggedIn"]),
  },
  data() {
    return {
      urlToCopy: "",
      openMap: false,
      center: {
        lat: null,
        lng: null,
      },
      property: [],
      previewId: this.$route.params.id,
      previewAddress: this.$route.params.address,
      image: 0,
      imageSelected: 1,
      dataImages: [],
      dataVideos: [],
      dataFiles: [],
      enablePreview: true,
      selectedCarouselItem: {},
      location: "",
      energyClassColor: "",
    };
  },
  methods: {
    copyHandler() {
      const urlToCopy = document.querySelector("#copy");
      urlToCopy.setAttribute("type", "text");
      urlToCopy.select();
      try {
        const successful = document.execCommand("copy");
        const msg = successful ? "successful" : "unsuccessful";
        console.log(msg);
      } catch (err) {
        console.log(err);
      }
      urlToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },

    serialize(data) {
      if (data === 0 || data === null) {
        return `Cena na vyžádání`;
      } else
        return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + ` Kč`;
    },

    energyHandler() {
      if (this.property.energyClass === "A") {
        this.energyClassColor = "#318A30";
      } else if (this.property.energyClass === "B") {
        this.energyClassColor = "#35C436";
      } else if (this.property.energyClass === "C") {
        this.energyClassColor = "#B7E63E";
      } else if (this.property.energyClass === "D") {
        this.energyClassColor = "#FFB846";
      } else if (this.property.energyClass === "E") {
        this.energyClassColor = "#F66E48";
      } else if (this.property.energyClass === "F") {
        this.energyClassColor = "#EE484A";
      } else if (this.property.energyClass === "G") {
        this.energyClassColor = "#FF3838";
      }
    },

    chooseMediaHandler(media, mediaType) {
      this.enablePreview = false;
      this.selectedCarouselItem = {
        src: mediaType === "image" ? media.images : media.videos,
        type: mediaType,
      };
    },

    imageSelectHandler(x) {
      if (x > 0) {
        return (this.imageSelected = x);
      }
    },
    async save() {
      try {
        this.$router.push("/nemovitost");
      } catch (error) {
        this.$toast(this.$t("ErrorMessages.ErrorWhileSaving"), {
          className: ["et-alert"],
        });
      }
    },
  },

  async mounted() {
    // get id of property from url
    const url = window.location.pathname;
    this.location = url.split("/").splice(3)[0];
    // ADVERT URL TO COPY
    this.urlToCopy = `${process.env.VUE_APP_FRONTEND_URL}${window.location.pathname}`;

    // findOne this property
    const result = await axios.get(
      `${process.env.VUE_APP_STRAPI_API_URL}/properties/${this.location}`
    );

    // save into state variable
    this.property = result.data;
    // image service
    this.dataImages = await getImagesNoLogged(this.location);
    this.dataVideos = await getVideosNoLogged(this.location);
    if (this.property) {
      this.center.lng = this.property.longitude;
      this.center.lat = this.property.latitude;
      this.openMap = true;
      this.energyHandler();
    }
  },
};
</script>

<style scoped>
.fill-svg {
  fill: rgb(255, 255, 255);
}

.fill-svg:hover {
  fill: rgb(206, 206, 206);
}
</style>
>
